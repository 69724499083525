@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  list-style-position: inside;
}

body {
  font-size: 16px;
  font-family: NotoSansArmenianRegular, 'Roboto', sans-serif;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #666;
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0px 10px;
}

header .container .div_img a {
  width: 120px;
  height: 120px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
}

header .container .div_img a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

header .container .tel_number {
  position: absolute;
  top: 0px;
  right: 10px;
  display: inline-block;
  padding: 7px;
  background: #59AD4F;
  color: white;
  text-decoration: none;
}

header .container .partner_lang .partners {
  display: inline-block;
  margin-right: 8px;
}

header .container .partner_lang .partners a {
  text-decoration: none;
  color: #4e8528;
  padding: 5px;
}

header .container .partner_lang .partners a:hover {
  background: #4e8528;
  color: #fff;
}

header .container .partner_lang form {
  display: inline-block;
}

header .container .partner_lang form button {
  background: unset;
  border: 0;
  color: #4e8528;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
}

header .container .partner_lang form button:hover {
  background: #4e8528;
  color: #fff;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

nav {
  background: #168615;
  z-index: 99999;
}

nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 70px;
}

nav .container .menu-btn {
  display: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  z-index: 3;
}

nav .container .menu-btn .menu-btn span,
nav .container .menu-btn .menu-btn span::before,
nav .container .menu-btn .menu-btn span::after {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 2px;
  background-color: #fff;
}

nav .container .menu-btn .menu-btn span::before,
nav .container .menu-btn .menu-btn span::after {
  content: '';
  display: block;
  transition: 0.5s;
}

nav .container .menu-btn .menu-btn span::before {
  transform: translateY(-5px);
}

nav .container .menu-btn .menu-btn_active span {
  height: 0;
}

nav .container .menu-btn .menu-btn span::after {
  transform: translateY(5px);
}

nav .container .menu-btn .menu-btn_active span:before {
  transform: rotate(-45deg);
  transform-origin: center;
}

nav .container .menu-btn .menu-btn_active span:after {
  transform: rotate(45deg);
  transform-origin: center;
}

nav .container .menu {
  list-style-type: none;
  display: flex;
}

nav .container .menu li {
  margin-right: 15px;
}

nav .container .menu li a {
  color: white;
  text-decoration: none;
  padding-bottom: 5px;
}

nav .container .menu li a:hover {
  border-bottom: 2px solid #fff;
}

nav .container form {
  display: flex;
  align-items: center;
}

nav .container form input {
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 7px 7px 7px 30px;
  margin-right: 7px;
  background-image: url("../img/icons/search.png");
  background-repeat: no-repeat;
  background-size: 9%;
  background-position: 5px 5px;
  cursor: pointer;
}

nav .container form a {
  display: inline-flex;
  text-decoration: none;
  position: relative;
}

nav .container form a img {
  width: 35px;
  height: 35px;
}

nav .container form a .circle {
  font-size: 11px;
  color: #fff;
  background: #FC2B33;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -5px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main {
  margin-top: 30px;
}

main .section_slider {
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-gap: 20px;
}

main .section_slider .swiper-container {
  width: 100%;
  height: 100%;
}

main .section_slider .swiper-container .swiper-wrapper {
  height: unset;
}

main .section_slider .swiper-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  position: relative;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

main .section_slider .swiper-container .swiper-slide img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

main .section_slider .swiper-container .swiper-slide .slider_name {
  position: absolute;
  top: 0;
  left: 0;
  background: #00A9F1;
  color: white;
  padding: 5px;
}

main .section_slider .swiper-container .swiper-slide .slider_text {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  width: 100%;
  background: black;
  color: white;
  opacity: .7;
  padding: 10px;
}

main .section_slider .slider_right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

main .section_slider .slider_right .right_img {
  position: relative;
  width: 300px;
  overflow: hidden;
}

main .section_slider .slider_right .right_img img {
  width: 100%;
  min-height: 193px;
  max-height: 193px;
  object-fit: cover;
  display: block;
}

main .section_slider .slider_right .right_img .discount_slider_div {
  background: rgba(252, 43, 50, 0.8);
  position: absolute;
  top: 115px;
  right: -91px;
  padding: 10px;
  width: 100%;
  transform: rotate(-45deg);
  text-align: center;
  color: white;
  text-decoration: none;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

.categories_section .categories_p {
  color: #4e8528;
  font-size: 30px;
  margin-bottom: 20px;
}

.categories_section .category_products .step2 {
  background: #FC2B33 !important;
  transition: .5s;
}

.categories_section .category_products .step2:hover {
  background: #C12127 !important;
}

.categories_section .category_products .step3 {
  background: #FF8500 !important;
  transition: .5s;
}

.categories_section .category_products .step3:hover {
  background: #BB6100 !important;
}

.categories_section .category_products .section_products {
  background: #00A9F1;
  padding: 10px;
  width: fit-content;
  color: white;
  transition: .5s;
  text-decoration: none;
}

.categories_section .category_products .section_products:hover {
  background: #0070A0;
}

.categories_section .category_products .main_products {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.categories_section .category_products .main_products .cat_product {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  padding: 5px;
  transition: .5s;
}

.categories_section .category_products .main_products .cat_product:hover {
  box-shadow: 0 0 10px black;
}

.categories_section .category_products .main_products .cat_product img {
  width: 100%;
  border-radius: 7px;
  height: 150px;
  object-fit: cover;
}

.categories_section .category_products .main_products .cat_product .product_name,
.categories_section .category_products .main_products .cat_product .product_quantity,
.categories_section .category_products .main_products .cat_product .prosuct_proce {
  color: black;
  font-weight: 600;
  text-align: center;
  padding: 5px 0;
}

.categories_section .category_products .main_products .cat_product .quantity_input {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.categories_section .category_products .main_products .cat_product .quantity_input .input_quantity {
  width: 70%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid gray;
  text-align: center;
  margin: 0 5px;
}

.categories_section .category_products .main_products .cat_product .quantity_input .minus,
.categories_section .category_products .main_products .cat_product .quantity_input .plus {
  background: #00A9F1;
  color: white;
  padding: 7px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s;
}

.categories_section .category_products .main_products .cat_product .quantity_input .minus:hover,
.categories_section .category_products .main_products .cat_product .quantity_input .plus:hover {
  background: #0070A0;
}

.categories_section .category_products .main_products .cat_product .quantity_input .input_quantity::-webkit-inner-spin-button,
.categories_section .category_products .main_products .cat_product .quantity_input .input_quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.categories_section .category_products .main_products .cat_product .send_basket {
  background: #FC2B33;
  color: #fff;
  padding: 5px;
  width: 100%;
  border-radius: 7px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  transition: .5s;
}

.categories_section .category_products .main_products .cat_product .send_basket:hover {
  background: #C12127;
}

.categories_section .category_products .main_products .cat_product .send_basket img {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

section .sections_all_p {
  color: #4e8528;
  font-size: 30px;
  margin-bottom: 20px;
}

section .sections_all_products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

section .sections_all_products .filter_section {
  margin-bottom: 20px;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

section .sections_all_products .filter_section label {
  display: inline-block;
  width: fit-content;
}

section .sections_all_products .filter_section .check_input {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

section .sections_all_products .filter_section .check_input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

section .sections_all_products .filter_section .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 7px;
}

section .sections_all_products .filter_section .check_input:hover input ~ .checkmark {
  background-color: #ccc;
}

section .sections_all_products .filter_section .check_input input:checked ~ .check1 {
  background-color: #ED8226;
}

section .sections_all_products .filter_section .check_input input:checked ~ .check2 {
  background-color: #2C912B;
}

section .sections_all_products .filter_section .check_input input:checked ~ .check3 {
  background-color: #BB0C0B;
}

section .sections_all_products .filter_section .check_input input:checked ~ .check4 {
  background-color: #00A9F1;
}

section .sections_all_products .filter_section .check_input input:checked ~ .check5 {
  background-color: #C9B700;
}

section .sections_all_products .filter_section .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

section .sections_all_products .filter_section .check_input input:checked ~ .checkmark:after {
  display: block;
}

section .sections_all_products .filter_section .check_input .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

section .sections_all_products .filter_section .filter_section_name {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;
  width: 70%;
}

section .sections_all_products .filter_section .filter_section_name h1 {
  margin-bottom: 0;
  width: 100%;
  font-size: 20px;
}

section .sections_all_products .filter_section .filter_section_name .h1_1 {
  color: #ED8226;
  border-bottom: 4px solid #ED8226;
}

section .sections_all_products .filter_section .filter_section_name .h1_2 {
  color: #2C912B;
  border-bottom: 4px solid #2C912B;
}

section .sections_all_products .filter_section .filter_section_name .h1_3 {
  color: #BB0C0B;
  border-bottom: 4px solid #BB0C0B;
}

section .sections_all_products .filter_section .filter_section_name .h1_4 {
  color: #00A9F1;
  border-bottom: 4px solid #00A9F1;
}

section .sections_all_products .filter_section .filter_section_name .h1_5 {
  color: #C9B700;
  border-bottom: 4px solid #C9B700;
}

section .sections_all_products .filter_section .filter_section_name .h1_6 {
  color: #1E891C;
  border-bottom: 4px solid #1E891C;
}

section .sections_all_products .filter_section .filter_section_name img {
  width: 40px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

.section_info {
  margin: 40px 0;
}

.section_info .information_header_text {
  margin-bottom: 30px;
}

.section_info .information_header_text p {
  color: #4e8528;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.section_info .section_information_all {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.section_info .section_information_all .info_all .information_div {
  display: flex;
  margin-bottom: 50px;
}

.section_info .section_information_all .info_all .information_div .number_info {
  font-size: 80px;
  color: #4e8528;
  align-self: center;
  margin-right: 20px;
  font-family: cursive;
}

.section_info .section_information_all .info_all .information_div .div_text .text_head {
  color: #4e8528;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.section_info .section_information_all .info_all .information_div .div_text .text_p {
  color: gray;
  font-size: 13px;
}

.section_info .section_information_all .info_img {
  align-self: center;
}

.section_info .section_information_all .info_img img {
  width: 520px;
  height: 450px;
}

.delivery_speed {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: #168615;
  padding: 10px 0 !important;
  margin-bottom: 30px !important;
}

.delivery_speed .delivery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #fff;
  padding: 10px;
}

.delivery_speed .delivery .img_div {
  margin-right: 7px;
}

.delivery_speed .delivery .img_div img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.delivery_speed .delivery .delivery_text {
  color: #fff;
}

.delivery_speed .delivery .delivery_text .delivery_head {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.delivery_speed .delivery .delivery_text .del_text_info {
  font-size: 12px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main .search_filter {
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-gap: 20px;
}

main .search_filter .filter .filter_name .sections_all_p {
  font-size: 30px;
  color: gray;
}

main .search_filter .filter .filter_name .sections_all {
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

main .search_filter .filter .filter_name .sections_all .check_input {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

main .search_filter .filter .filter_name .sections_all .check_input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

main .search_filter .filter .filter_name .sections_all .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 7px;
}

main .search_filter .filter .filter_name .sections_all .check_input:hover input ~ .checkmark {
  background-color: #ccc;
}

main .search_filter .filter .filter_name .sections_all .check_input input:checked ~ .check1 {
  background-color: #ED8226;
}

main .search_filter .filter .filter_name .sections_all .check_input input:checked ~ .check2 {
  background-color: #2C912B;
}

main .search_filter .filter .filter_name .sections_all .check_input input:checked ~ .check3 {
  background-color: #BB0C0B;
}

main .search_filter .filter .filter_name .sections_all .check_input input:checked ~ .check4 {
  background-color: #00A9F1;
}

main .search_filter .filter .filter_name .sections_all .check_input input:checked ~ .check5 {
  background-color: #C9B700;
}

main .search_filter .filter .filter_name .sections_all .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

main .search_filter .filter .filter_name .sections_all .check_input input:checked ~ .checkmark:after {
  display: block;
}

main .search_filter .filter .filter_name .sections_all .check_input .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

main .search_filter .filter .filter_name .sections_all .check_all_div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

main .search_filter .filter .filter_name .sections_all .check_all_div input {
  margin-right: 5px;
}

main .search_filter .filter .filter_name .sections_all .filter_section {
  margin-bottom: 20px;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name h1 {
  margin-bottom: 0;
  width: 100%;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name .h1_1 {
  color: #ED8226;
  border-bottom: 4px solid #ED8226;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name .h1_2 {
  color: #2C912B;
  border-bottom: 4px solid #2C912B;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name .h1_3 {
  color: #BB0C0B;
  border-bottom: 4px solid #BB0C0B;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name .h1_4 {
  color: #00A9F1;
  border-bottom: 4px solid #00A9F1;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name .h1_5 {
  color: #C9B700;
  border-bottom: 4px solid #C9B700;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name .h1_6 {
  color: #1E891C;
  border-bottom: 4px solid #1E891C;
}

main .search_filter .filter .filter_name .sections_all .filter_section .filter_section_name img {
  width: 50px;
}

main .search_filter .filter_result .filter_result_p {
  font-size: 30px;
  border-bottom: 3px solid black;
  padding-bottom: 10px;
}

main .search_filter .filter_result .filter_no_result {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

main .search_filter .filter_result .filter_no_result .filter_img p {
  color: gray;
  text-align: center;
}

main .search_filter .filter_result .filter_main_products {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;
}

main .search_filter .filter_result .filter_main_products .cat_product {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  padding: 5px;
  transition: .5s;
}

main .search_filter .filter_result .filter_main_products .cat_product:hover {
  box-shadow: 0 0 10px black;
}

main .search_filter .filter_result .filter_main_products .cat_product img {
  width: 100%;
  border-radius: 7px;
  height: 150px;
  object-fit: cover;
}

main .search_filter .filter_result .filter_main_products .cat_product .product_name,
main .search_filter .filter_result .filter_main_products .cat_product .product_quantity,
main .search_filter .filter_result .filter_main_products .cat_product .prosuct_proce {
  color: black;
  font-weight: 600;
  text-align: center;
  padding: 5px 0;
}

main .search_filter .filter_result .filter_main_products .cat_product .quantity_input {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

main .search_filter .filter_result .filter_main_products .cat_product .quantity_input .input_quantity {
  width: 70%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid gray;
  text-align: center;
  margin: 0 5px;
}

main .search_filter .filter_result .filter_main_products .cat_product .quantity_input .minus,
main .search_filter .filter_result .filter_main_products .cat_product .quantity_input .plus {
  background: #00A9F1;
  color: white;
  padding: 7px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s;
}

main .search_filter .filter_result .filter_main_products .cat_product .quantity_input .minus:hover,
main .search_filter .filter_result .filter_main_products .cat_product .quantity_input .plus:hover {
  background: #0070A0;
}

main .search_filter .filter_result .filter_main_products .cat_product .quantity_input .input_quantity::-webkit-inner-spin-button,
main .search_filter .filter_result .filter_main_products .cat_product .quantity_input .input_quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

main .search_filter .filter_result .filter_main_products .cat_product .send_basket {
  background: #FC2B33;
  color: #fff;
  padding: 5px;
  width: 100%;
  border-radius: 7px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  transition: .5s;
}

main .search_filter .filter_result .filter_main_products .cat_product .send_basket:hover {
  background: #C12127;
}

main .search_filter .filter_result .filter_main_products .cat_product .send_basket img {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main .cont_categores .select_categories .filter_section_name {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;
  width: 40%;
}

main .cont_categores .select_categories .filter_section_name h1 {
  margin-bottom: 0;
  width: 100%;
}

main .cont_categores .select_categories .filter_section_name .h1_1 {
  color: #ED8226;
  border-bottom: 4px solid #ED8226;
}

main .cont_categores .select_categories .filter_section_name img {
  width: 50px;
}

main .cont_categores .categories_products {
  margin: 30px 0;
}

main .cont_categores .categories_products .filter_main_products {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;
}

main .cont_categores .categories_products .filter_main_products .cat_product {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  padding: 5px;
  transition: .5s;
}

main .cont_categores .categories_products .filter_main_products .cat_product:hover {
  box-shadow: 0 0 10px black;
}

main .cont_categores .categories_products .filter_main_products .cat_product img {
  width: 100%;
  border-radius: 7px;
  height: 150px;
  object-fit: cover;
}

main .cont_categores .categories_products .filter_main_products .cat_product .product_name,
main .cont_categores .categories_products .filter_main_products .cat_product .product_quantity,
main .cont_categores .categories_products .filter_main_products .cat_product .prosuct_proce {
  color: black;
  font-weight: 600;
  text-align: center;
  padding: 5px 0;
}

main .cont_categores .categories_products .filter_main_products .cat_product .quantity_input {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

main .cont_categores .categories_products .filter_main_products .cat_product .quantity_input .input_quantity {
  width: 70%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid gray;
  text-align: center;
  margin: 0 5px;
}

main .cont_categores .categories_products .filter_main_products .cat_product .quantity_input .minus,
main .cont_categores .categories_products .filter_main_products .cat_product .quantity_input .plus {
  background: #00A9F1;
  color: white;
  padding: 7px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s;
}

main .cont_categores .categories_products .filter_main_products .cat_product .quantity_input .minus:hover,
main .cont_categores .categories_products .filter_main_products .cat_product .quantity_input .plus:hover {
  background: #0070A0;
}

main .cont_categores .categories_products .filter_main_products .cat_product .quantity_input .input_quantity::-webkit-inner-spin-button,
main .cont_categores .categories_products .filter_main_products .cat_product .quantity_input .input_quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

main .cont_categores .categories_products .filter_main_products .cat_product .send_basket {
  background: #FC2B33;
  color: #fff;
  padding: 5px;
  width: 100%;
  border-radius: 7px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  transition: .5s;
}

main .cont_categores .categories_products .filter_main_products .cat_product .send_basket:hover {
  background: #C12127;
}

main .cont_categores .categories_products .filter_main_products .cat_product .send_basket img {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main .big_sales {
  margin-bottom: 30px;
}

main .big_sales .sales_div_name h1 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  border-bottom: 2px solid #38902F;
}

main .big_sales .sales_divs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

main .big_sales .sales_divs .sales_parts {
  text-align: center;
  width: 100%;
}

main .big_sales .sales_divs .sales_parts a {
  color: unset;
  text-decoration: none;
  display: block;
}

main .big_sales .sales_divs .sales_parts .div_img_sales {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: .5;
}

main .big_sales .sales_divs .sales_parts .div_img_sales:hover {
  box-shadow: 0 0 10px black;
}

main .big_sales .sales_divs .sales_parts img {
  width: 100px;
  padding: 15px;
}

main .big_sales .sales_divs .sales_parts p {
  font-size: 30px;
  font-weight: 700;
  margin: 10px 0;
}

main .big_sales .sales_divs .sales_parts .h1_1 {
  color: #ED8226;
}

main .big_sales .sales_divs .sales_parts .h1_2 {
  color: #2C912B;
}

main .big_sales .sales_divs .sales_parts .h1_3 {
  color: #BB0C0B;
}

main .big_sales .sales_divs .sales_parts .h1_4 {
  color: #00A9F1;
}

main .big_sales .sales_divs .sales_parts .h1_5 {
  color: #C9B700;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main .cont_basket {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: 20px;
}

main .cont_basket .pay_for_products .pay_head_text p {
  color: #4e8528;
  font-size: 20px;
  font-weight: 700;
}

main .cont_basket .pay_for_products .products_quant_price {
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  margin-bottom: 20px;
}

main .cont_basket .pay_for_products .products_quant_price .product_quantity {
  border-bottom: 1px solid gray;
}

main .cont_basket .pay_for_products .products_quant_price .product_quantity .p_quantity {
  margin: 7px 0;
}

main .cont_basket .pay_for_products .products_quant_price .product_quantity .p_quantity p {
  color: #4e8528;
}

main .cont_basket .pay_for_products .products_quant_price .product_quantity .span_quantity span {
  color: gray;
}

main .cont_basket .pay_for_products .products_quant_price .product_price .p_price {
  margin: 7px 0;
}

main .cont_basket .pay_for_products .products_quant_price .product_price .p_price p {
  color: #4e8528;
}

main .cont_basket .pay_for_products .products_quant_price .product_price .span_price span {
  color: gray;
}

main .cont_basket .pay_for_products .name_surname,
main .cont_basket .pay_for_products .email_client,
main .cont_basket .pay_for_products .number_client,
main .cont_basket .pay_for_products .address_client {
  margin-bottom: 20px;
}

main .cont_basket .pay_for_products .name_surname label,
main .cont_basket .pay_for_products .email_client label,
main .cont_basket .pay_for_products .number_client label,
main .cont_basket .pay_for_products .address_client label {
  color: #4e8528;
  display: block;
  font-size: 18px;
  font-weight: 700;
}

main .cont_basket .pay_for_products .name_surname input,
main .cont_basket .pay_for_products .email_client input,
main .cont_basket .pay_for_products .number_client input,
main .cont_basket .pay_for_products .address_client input {
  margin-top: 10px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  border: 0;
  width: 100%;
}

main .cont_basket .pay_for_products .address_client {
  margin-bottom: 20px;
}

main .cont_basket .pay_for_products .address_client label {
  color: #4e8528;
  display: block;
  font-size: 18px;
  font-weight: 700;
}

main .cont_basket .pay_for_products .address_client textarea {
  margin-top: 10px;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  width: 100%;
  border: 0;
  height: 100px;
}

main .cont_basket .pay_for_products .date_delivery {
  margin-bottom: 20px;
}

main .cont_basket .pay_for_products .date_delivery label {
  color: #4e8528;
  display: block;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

main .cont_basket .pay_for_products .date_delivery input {
  margin-top: 10px;
  padding: 7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  border: 0;
}

main .cont_basket .pay_for_products .date_time,
main .cont_basket .pay_for_products .pay_type {
  margin: 15px 0;
}

main .cont_basket .pay_for_products .date_time .delivery_label,
main .cont_basket .pay_for_products .date_time .pay_label,
main .cont_basket .pay_for_products .pay_type .delivery_label,
main .cont_basket .pay_for_products .pay_type .pay_label {
  color: #4e8528;
  display: block;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 10px;
}

main .cont_basket .pay_for_products .date_time .del_div_checkbox,
main .cont_basket .pay_for_products .pay_type .del_div_checkbox {
  display: flex;
  justify-content: space-between;
}

main .cont_basket .pay_for_products .date_time .del_div_checkbox .chech_button1,
main .cont_basket .pay_for_products .pay_type .del_div_checkbox .chech_button1 {
  color: #4e8528;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #4e8528;
  cursor: pointer;
  transition: .5s !important;
}

main .cont_basket .pay_for_products .date_time .del_div_checkbox .chech_button1.active_delivery_time,
main .cont_basket .pay_for_products .date_time .del_div_checkbox .chech_button1.active_delivery_type,
main .cont_basket .pay_for_products .pay_type .del_div_checkbox .chech_button1.active_delivery_time,
main .cont_basket .pay_for_products .pay_type .del_div_checkbox .chech_button1.active_delivery_type {
  background: #4e8528;
  color: #fff;
}

main .cont_basket .pay_for_products .date_time .del_div_checkbox .chech_button1:hover,
main .cont_basket .pay_for_products .pay_type .del_div_checkbox .chech_button1:hover {
  background: #5B992E;
  color: #fff;
  border: 1px solid transparent;
}

main .cont_basket .pay_for_products .date_time .del_div_checkbox .chech_button1 label input,
main .cont_basket .pay_for_products .pay_type .del_div_checkbox .chech_button1 label input {
  position: fixed;
  top: -20px;
}

main .cont_basket .pay_for_products .date_time .pay_div_checkbox,
main .cont_basket .pay_for_products .pay_type .pay_div_checkbox {
  justify-content: unset;
}

main .cont_basket .pay_for_products .date_time .pay_div_checkbox .check_pay,
main .cont_basket .pay_for_products .pay_type .pay_div_checkbox .check_pay {
  margin-right: 10px;
}

main .cont_basket .pay_for_products .pay_images {
  margin-bottom: 40px;
}

main .cont_basket .pay_for_products .pay_images img {
  width: 100%;
}

main .cont_basket .pay_for_products .send_all {
  margin-bottom: 20px;
}

main .cont_basket .pay_for_products .send_all button {
  color: #fff;
  background: #FC2B33;
  width: 100%;
  padding: 15px 0;
  border-radius: 7px;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  transition: .5s;
}

main .cont_basket .pay_for_products .send_all button:hover {
  background: #DB0000;
  color: #fff;
}

main .cont_basket .see_products {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  height: 150px;
  margin-top: 45px;
}

main .cont_basket .see_products .product_item {
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: fit-content;
  border-radius: 10px;
  height: 150px;
  transition: .5s;
}

main .cont_basket .see_products .product_item:hover {
  box-shadow: 0 0 10px black;
}

main .cont_basket .see_products .product_item .img_item {
  border-radius: 7px;
}

main .cont_basket .see_products .product_item .img_item img {
  width: 200px;
  height: 150px;
  border-radius: 10%;
  object-fit: contain;
  padding: 0 0 0 7px;
}

main .cont_basket .see_products .product_item .div_product_item .name_item {
  color: black;
  font-weight: 700;
  text-align: center;
}

main .cont_basket .see_products .product_item .div_product_item .quantity_item {
  font-weight: 600;
  text-align: center;
  margin: 5px 0;
}

main .cont_basket .see_products .product_item .div_product_item .quantity_input {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
}

main .cont_basket .see_products .product_item .div_product_item .quantity_input .input_quantity {
  width: 70%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid gray;
  text-align: center;
  margin: 0 5px;
  font-weight: 700;
}

main .cont_basket .see_products .product_item .div_product_item .quantity_input .minus,
main .cont_basket .see_products .product_item .div_product_item .quantity_input .plus {
  background: #00A9F1;
  color: white;
  padding: 7px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s;
}

main .cont_basket .see_products .product_item .div_product_item .quantity_input .minus:hover,
main .cont_basket .see_products .product_item .div_product_item .quantity_input .plus:hover {
  background: #0070A0;
}

main .cont_basket .see_products .product_item .div_product_item .quantity_input .input_quantity::-webkit-inner-spin-button,
main .cont_basket .see_products .product_item .div_product_item .quantity_input .input_quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

main .cont_basket .see_products .product_item .div_product_item .div_price_del {
  display: flex;
  justify-content: space-around;
  font-weight: 600;
}

main .cont_basket .see_products .product_item .div_product_item .div_price_del button {
  background: unset;
  border: 0;
  cursor: pointer;
  font-weight: 600;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main .big_sales_all {
  min-height: 100vh;
}

main .big_sales_all .sales_div_all_name h1 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  border-bottom: 2px solid #38902F;
}

main .big_sales_all .sales_divs_parts_all {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

main .big_sales_all .sales_divs_parts_all a {
  color: black;
  font-size: 30px;
  text-decoration: none;
  transition: .5s;
}

main .big_sales_all .sales_divs_parts_all a .parts_one {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: .5;
}

main .big_sales_all .sales_divs_parts_all a:hover {
  box-shadow: 0 0 10px black;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main .sales_product_section_p h1 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  border-bottom: 2px solid #38902F;
}

main .sales_product_section_page {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
}

main .sales_product_section_page .cat_product {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  padding: 5px;
  transition: .5s;
}

main .sales_product_section_page .cat_product:hover {
  box-shadow: 0 0 10px black;
}

main .sales_product_section_page .cat_product .image_sales_product {
  position: relative;
  overflow: hidden;
  height: 150px;
}

main .sales_product_section_page .cat_product .image_sales_product img {
  width: 100%;
  border-radius: 7px;
  min-height: 150px;
  max-height: 150px;
  overflow: hidden;
  object-fit: cover;
}

main .sales_product_section_page .cat_product .image_sales_product .img_p_sales {
  background: rgba(252, 43, 50, 0.8);
  position: absolute;
  top: 80px;
  right: -46px;
  padding: 10px;
  width: 100%;
  transform: rotate(-45deg);
  text-align: center;
  color: white;
}

main .sales_product_section_page .cat_product .product_name,
main .sales_product_section_page .cat_product .product_quantity,
main .sales_product_section_page .cat_product .prosuct_proce {
  color: black;
  font-weight: 600;
  text-align: center;
  padding: 5px 0;
}

main .sales_product_section_page .cat_product .quantity_input {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

main .sales_product_section_page .cat_product .quantity_input .input_quantity {
  width: 70%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid gray;
  text-align: center;
  margin: 0 5px;
}

main .sales_product_section_page .cat_product .quantity_input .minus,
main .sales_product_section_page .cat_product .quantity_input .plus {
  background: #F1870C;
  color: white;
  padding: 7px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s;
}

main .sales_product_section_page .cat_product .quantity_input .minus:hover,
main .sales_product_section_page .cat_product .quantity_input .plus:hover {
  background: #BB6100;
}

main .sales_product_section_page .cat_product .quantity_input .input_quantity::-webkit-inner-spin-button,
main .sales_product_section_page .cat_product .quantity_input .input_quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

main .sales_product_section_page .cat_product .send_basket {
  background: #FC2B33;
  color: #fff;
  padding: 5px;
  width: 100%;
  border-radius: 7px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  transition: .5s;
}

main .sales_product_section_page .cat_product .send_basket:hover {
  background: #C12127;
}

main .sales_product_section_page .cat_product .send_basket img {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main .delivery_payment .delivery_p h1 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  border-bottom: 2px solid #38902F;
}

main .delivery_payment .delivery_text p {
  columns: 2;
  color: #4e8528;
}

main .delivery_payment .table_delivery {
  margin: 30px 0;
}

main .delivery_payment .table_delivery table {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
}

main .delivery_payment .table_delivery th,
main .delivery_payment .table_delivery td {
  text-align: center;
  padding: 8px;
  font-weight: 700;
  width: 30%;
}

main .delivery_payment .table_delivery tr:nth-child(even) {
  background-color: #E6F2DC;
}

main .delivery_payment .table_delivery th {
  background-color: #fff;
  color: #4e8528;
  text-align: center;
  font-size: 14px;
}

main .delivery_payment .payment_all {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  margin-bottom: 40px;
}

main .delivery_payment .payment_all .payment_online .div_all_online {
  display: flex;
  margin-bottom: 10px;
}

main .delivery_payment .payment_all .payment_online .div_all_online .payment_p {
  color: #4e8528;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  margin-right: 15px;
}

main .delivery_payment .payment_all .payment_online .div_all_online img {
  width: 30px;
  height: 30px;
}

main .delivery_payment .payment_all .payment_text {
  color: #4e8528;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

.contact_main {
  margin-top: 0;
}

.contact_main .contact_section {
  padding-top: 0;
  min-height: 100vh;
}

.contact_main .contact_section .div_map {
  width: 100%;
}

.contact_main .contact_section .div_map iframe {
  width: 100%;
}

.contact_main .contact_section .contact_info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin-top: 50px;
}

.contact_main .contact_section .contact_info .contact_info_all {
  text-align: center;
  padding: 30px 0;
}

.contact_main .contact_section .contact_info .contact_info_all img {
  margin: 5px;
}

.contact_main .contact_section .contact_info .contact_info_all .name_info {
  color: #4e8528;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 20px;
}

.contact_main .contact_section .contact_info .contact_info_all .value_info {
  color: black;
}

.contact_main .contact_section .contact_info .contact_info_all .value_info:hover {
  color: #5E8B2A;
}

.contact_main .contact_section .contact_info .contact_info_all .value_info a {
  color: black;
  text-decoration: none;
}

.contact_main .contact_section .contact_info .contact_info_all .value_info a:hover {
  color: #5E8B2A;
}

.contact_main .contact_section .contact_people {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin: 30px 0;
}

.contact_main .contact_section .contact_people .cont_peop {
  text-align: center;
}

.contact_main .contact_section .contact_people .cont_peop img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
}

.contact_main .contact_section .contact_people .cont_peop .cont_name {
  color: #4e8528;
  margin-bottom: 10px;
}

.contact_main .contact_section .contact_people .cont_peop .cont_text {
  width: 70%;
  margin: 0 auto 10px;
  min-height: 110px;
}

.contact_main .contact_section .contact_people .cont_peop .cont_phone a {
  color: black;
  text-decoration: none;
}

.contact_main .contact_section .contact_people .cont_peop .cont_phone a:hover {
  color: #5E8B2A;
}

.contact_main .contact_section .contact_people .cont_peop .cont_mail a {
  color: black;
  text-decoration: none;
}

.contact_main .contact_section .contact_people .cont_peop .cont_mail a:hover {
  color: #5E8B2A;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

main .partners_cont {
  min-height: 100vh;
}

main .partners_cont h1 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  border-bottom: 2px solid #38902F;
}

main .partners_cont .partners_divs {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  padding: 20px 0 30px 0;
}

main .partners_cont .partners_divs .partners_parts {
  text-align: center;
  width: 100%;
}

main .partners_cont .partners_divs .partners_parts a {
  color: unset;
  text-decoration: none;
  display: block;
}

main .partners_cont .partners_divs .partners_parts .div_img_sales {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: .5s;
}

main .partners_cont .partners_divs .partners_parts .div_img_sales:hover {
  box-shadow: 0 0 10px black;
}

main .partners_cont .partners_divs .partners_parts img {
  width: 150px;
  padding: 15px;
}

main .partners_cont .partners_divs .partners_parts p {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
  text-align: center;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

footer {
  background: #168615;
}

footer .footer_head {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 20px 30px;
  border-bottom: 1px solid #fff;
}

footer .footer_head .footer_info .head_p {
  color: #fff;
  margin-bottom: 7px;
  font-weight: 700;
}

footer .footer_head .footer_info .info_ul li {
  list-style-type: none;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
}

footer .footer_head .footer_info .info_ul li a {
  display: inline-block;
  width: 100%;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  transition: .5s;
}

footer .footer_head .footer_info .info_ul li a:hover {
  color: #00ce90;
}

footer .footer_head .footer_info .text_send_mail {
  color: #fff;
  font-size: 15px;
}

footer .footer_head .footer_info .send_mail_div_head {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

footer .footer_head .footer_info .send_mail_div_head input {
  padding: 8px;
  border-radius: 7px;
  margin-right: 10px;
  border: 1px solid #168615;
}

footer .footer_head .footer_info .send_mail_div_head button {
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  border: 1px solid #168615;
  padding: 2px 0;
}

footer .footer_head .footer_info .send_mail_div_head button img {
  width: 25px;
  height: 25px;
}

footer .footer_copy {
  padding: 10px 0;
}

footer .footer_copy p {
  color: white;
  text-align: center;
  font-size: 13px;
}

footer .footer_copy p a {
  color: white;
  text-decoration: none;
  padding: 1px 5px;
  border-bottom: 1px solid #116810;
  transition: 1s;
}

footer .footer_copy p a:hover {
  background: #116810;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px;
}

@font-face {
  font-family: NotoSansArmenianBold;
  src: url("../fonts/NotoSansArmenian-Bold.ttf");
}

@font-face {
  font-family: NotoSansArmenianRegular;
  src: url("../fonts/NotoSansArmenian-Regular.ttf");
}

@font-face {
  font-family: NotoSansArmenianMedium;
  src: url("../fonts/NotoSansArmenian-Medium.ttf");
}

@font-face {
  font-family: NotoSansArmenianLight;
  src: url("../fonts/NotoSansArmenian-Light.ttf");
}

@font-face {
  font-family: NotoSansArmenianThin;
  src: url("../fonts/NotoSansArmenian-Thin.ttf");
}

@media screen and (max-width: 1250px) {
  main .cont_basket .see_products {
    grid-template-columns: 1fr;
    margin: 0 auto;
    margin-top: 45px;
  }
}

@media screen and (max-width: 1100px) {
  section .sections_all_products {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1060px) {
  main .cont_basket .pay_for_products .date_time .del_div_checkbox .chech_button1,
  main .cont_basket .pay_for_products .pay_type .del_div_checkbox .chech_button1 {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1024px) {
  .section_info .information_header_text p {
    font-size: 25px;
  }

  .section_info .section_information_all {
    display: inline-block;
  }

  .section_info .section_information_all .info_all .information_div {
    margin-bottom: 50px;
  }

  .section_info .section_information_all .info_all .information_div .number_info {
    font-size: 70px;
    color: #4e8528;
    align-self: center;
    margin-right: 20px;
  }

  .section_info .section_information_all .info_all .information_div .div_text {
    padding: 0 20px;
  }

  .section_info .section_information_all .info_all .information_div .div_text .text_head {
    color: #4e8528;
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .section_info .section_information_all .info_img {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1000px) {
  main .search_filter {
    display: block;
  }

  main .cont_basket {
    display: block;
  }

  main .cont_basket .pay_for_products {
    width: 70%;
    margin: 0 auto;
  }

  main .cont_basket .see_products {
    height: unset;
  }

  main .cont_basket .see_products .product_item {
    margin: 0 auto;
  }
}

@media screen and (max-width: 786px) {
  main .big_sales_all .sales_divs_parts_all {
    grid-template-columns: 1fr;
    width: 80%;
    margin: 0 auto;
  }

  main .big_sales_all .sales_divs_parts_all .sales_parts p {
    font-size: 25px;
  }

  main .delivery_payment .delivery_text p {
    columns: 1;
  }

  main .delivery_payment .payment_all {
    display: block;
  }

  main .delivery_payment .payment_all .payment_online {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  nav .container .menu-btn {
    display: block;
  }

  nav .container .menu {
    flex-direction: column;
    position: absolute;
    top: 55px;
    left: -1000px;
    transition: 0.5s;
    background: #168615;
    width: 100%;
    z-index: 999999;
  }

  nav .container .menu li {
    margin-right: 0;
  }

  nav .container .menu li a {
    display: inline-block;
    width: 100%;
    padding: 10px;
  }

  nav .container .menu li a:hover {
    color: #fff;
  }

  nav .container .menu.acmenu {
    left: 0px;
    z-index: 999999;
  }

  nav .container form a .circle {
    right: 0;
  }

  .delivery_speed {
    display: block;
  }

  .delivery_speed .delivery {
    justify-content: unset;
    padding: 30px;
  }

  .delivery_speed .delivery .img_div {
    width: 100px;
    margin-right: 15px;
  }

  .delivery_speed .delivery .img_div img {
    width: 50px;
  }
}

@media screen and (max-width: 700px) {
  section .sections_all_products {
    grid-template-columns: 1fr;
  }

  section .sections_all_products .filter_section {
    width: unset;
    margin: 0 auto;
  }

  section .sections_all_products .filter_section .filter_section_name {
    width: 250px;
  }
}

@media screen and (max-width: 600px) {
  main .big_sales .sales_div_name h1 {
    font-size: 25px;
    text-align: center;
  }

  main .big_sales .sales_divs {
    grid-template-columns: 1fr;
    width: 80%;
    margin: 0 auto;
  }

  main .big_sales .sales_divs .sales_parts p {
    font-size: 25px;
  }

  main .big_sales_all .sales_div_all_name h1 {
    font-size: 25px;
    text-align: center;
  }

  main .sales_product_section_p h1 {
    font-size: 25px;
    text-align: center;
  }

  main .delivery_payment .delivery_p h1 {
    font-size: 25px;
    text-align: center;
  }

  .contact_main .contact_section .contact_info {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact_main .contact_section .contact_people {
    grid-template-columns: 1fr;
  }

  main .partners_cont h1 {
    font-size: 25px;
    text-align: center;
  }

  main .partners_cont .partners_divs {
    grid-template-columns: 1fr;
    width: 80%;
    margin: 0 auto;
  }

  main .partners_cont .partners_divs .sales_parts p {
    font-size: 25px;
  }
}

@media screen and (max-width: 580px) {
  header .container {
    flex-direction: column;
  }

  header .container .partner_lang {
    margin-bottom: 10px;
  }

  main .section_slider .swiper-container {
    margin-bottom: 20px;
  }

  main .section_slider .slider_right .right_img .discount_slider_div {
    font-size: 14px;
    top: 100px;
    right: -50px;
  }

  main {
    margin-top: 0;
  }

  .section_info .section_information_all .info_img img {
    width: 100%;
    height: unset;
  }

  main .sales_product_section_page .cat_product .image_sales_product .img_p_sales {
    font-size: 14px;
    top: 100px;
    right: -50px;
  }

  footer .footer_head {
    display: block;
  }

  footer .footer_head .footer_info {
    width: 80%;
    margin: 0 auto 30px;
    text-align: center;
  }

  footer .footer_head .footer_info .send_mail_div_head {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  main .cont_categores .select_categories .filter_section_name {
    width: 100%;
  }

  main .cont_categores .categories_products .filter_main_products {
    grid-template-columns: 1fr;
    width: 300px;
    margin: 30px auto 0;
  }

  main .cont_basket .see_products .product_item {
    display: block;
    height: unset;
    padding: 15px;
  }

  main .cont_basket .see_products .product_item .img_item {
    display: flex;
    justify-content: center;
    width: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 475px) {
  main .partners_cont .partners_divs {
    grid-template-columns: 1fr;
    width: 300px;
    margin: 30px auto 0;
  }
}

@media screen and (max-width: 450px) {
  main .search_filter .filter .filter_name .sections_all {
    margin-left: 0;
  }

  main .search_filter .filter_result .filter_result_p {
    font-size: 20px;
  }

  main .big_sales_all .sales_divs_parts_all {
    width: 250px;
  }

  main .big_sales_all .sales_divs_parts_all a {
    font-size: 20px;
  }
}

@media screen and (max-width: 425px) {
  main .section_slider .slider_right .right_img {
    width: 270px;
  }

  main .section_slider .slider_right .right_img .discount_slider_div {
    right: -55px;
  }

  main .section_slider {
    display: inline-block;
    width: 100%;
  }

  main .section_slider .slider_right {
    display: block;
    margin-top: 10px;
  }

  main .section_slider .slider_right .right_img {
    margin: 0 auto 15px auto;
  }

  .categories_section .category_products .main_products {
    grid-template-columns: 1fr;
    width: 300px;
    margin: 30px auto 0;
  }

  main .search_filter .filter_result .filter_main_products {
    grid-template-columns: 1fr;
    width: 300px;
    margin: 30px auto 0;
  }

  main .sales_product_section_page {
    grid-template-columns: repeat(1, 1fr);
  }

  main .sales_product_section_page .cat_product {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 350px) {
  main .big_sales .sales_divs .sales_parts .div_img_sales {
    width: 200px;
    height: 200px;
  }

  main .partners_cont .partners_divs .partners_parts .div_img_sales {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 426px) {
  main .section_slider {
    display: inline-block;
    width: 100%;
  }

  main .section_slider .slider_right {
    margin-top: 10px;
  }

  main .section_slider .slider_right .right_img {
    width: 100%;
  }
}

@media screen and (max-width: 580px) and (min-width: 426px) {
  .categories_section .category_products .main_products {
    grid-template-columns: 1fr 1fr;
  }

  main .sales_product_section_page {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) and (min-width: 581px) {
  .categories_section .category_products .main_products {
    grid-template-columns: 1fr 1fr 1fr;
  }

  main .sales_product_section_page {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1000px) and (min-width: 769px) {
  .categories_section .category_products .main_products {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .section_info .section_information_all .info_all .information_div .div_text .text_head {
    font-size: 23px;
  }

  .delivery_speed {
    grid-template-columns: 1fr 1fr;
  }

  .delivery_speed .delivery {
    padding: 30px;
  }

  main .sales_product_section_page {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1200px) and (min-width: 1001px) {
  .categories_section .category_products .main_products {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  main .sales_product_section_page {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
  main .search_filter .filter_result .filter_main_products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 768px) and (min-width: 426px) {
  main .search_filter .filter_result .filter_main_products {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1051px) {
  main .cont_categores .categories_products .filter_main_products {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1050px) and (min-width: 851px) {
  main .cont_categores .categories_products .filter_main_products {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 850px) and (min-width: 681px) {
  main .cont_categores .categories_products .filter_main_products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 680px) and (min-width: 501px) {
  main .cont_categores .categories_products .filter_main_products {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 900px) and (min-width: 601px) {
  main .big_sales .sales_divs {
    grid-template-columns: 1fr 1fr;
  }

  main .partners_cont .partners_divs {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 651px) and (min-width: 476px) {
  main .partners_cont .partners_divs {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 901px) and (min-width: 650px) {
  main .partners_cont .partners_divs {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1101px) and (min-width: 900px) {
  main .partners_cont .partners_divs {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1300px) and (min-width: 1100px) {
  main .partners_cont .partners_divs {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1000px) and (min-width: 581px) {
  footer .footer_head {
    grid-template-columns: 1fr 1fr;
  }

  footer .footer_head .footer_info {
    margin-bottom: 40px;
  }
}